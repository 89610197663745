<template>
  <div class="section">
    <nav class="level">
      <div class="level-left">
        <div class="columns is-multiline">
          <div class="column is-12">
            <h1 >Nueva Entrada </h1>
          </div>
          <div class="column is-12">
            <div class="level-item header-content">
              <div @click="$router.go(-1)">
                <span class="material-icons arrow-cursor">
                  chevron_left
                </span>
                <label class="vertical-center">Regresar</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="level-left">
      </div>
    </nav>
    <div class="box">
      <form-add-post />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    FormAddPost: () => import('@/components/Blog/FormAddPost')
  }
}
</script>

<style scoped>
  .section {
    padding: 0 20px 0 20px;
  }
  .arrow-cursor {
    cursor: pointer;
    margin-left: 15px;
  }

  .header-content {
    display: inline;
    color: #3b5088;
  }

  .vertical-center {
    position: absolute;
    padding: 0px;
    margin-left: 0px;
    font-size: 15px !important;
    cursor: pointer;
  }
</style>
